@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/highlightjs/styles/github.css";

small {
    display: block;
    color: #888;
}

.center {
    text-align: center;
}

.tag {
    border: 1px solid;
    border-radius: 4px;
    padding: 0 2px;
    &:hover {
        text-decoration: none;
        color: #ffffff;
        background-color: #007bff;
    }
}

pre {
    border: solid 1px #cfcfcf;
    &.fold {
        max-height: 30em;
        overflow-y: hidden;
        position: relative;
        &:after {
            position: absolute;
            content: "";
            left: 0px;
            top: 10em;
            height: 25em;
            width: 100%;
            background: linear-gradient(transparent, white);
            pointer-events: none;
        }
    }
}

div.filesource {
    background-color: #c0c0c0;
    padding: 0 8px;
    a {
        float: right;
        margin-left: 10px;
    }
    .filename {
        font-family: monospace;
    }
}

main.container, footer.container {
    max-width: 900px;
}

footer.container {
    margin-top: 30px;
    padding: 30px 0;
    border-top: 1px dashed #aaa;
}

@media (max-width: 767px) {
    .sidebar {
        padding-top: 30px;
    }
}

@media (min-width: 980px) {
    body {
        padding-bottom: 40px;
    }
}

body > main.container {
    padding: 80px 15px 0; /* 80px to make the container go all the way to the bottom of the topbar */
}
